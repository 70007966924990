@import '../../../../App.scss';

.icon {
  &--logo {
    width: 96px;
    height: $header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 16px;

    @include on-tablet {
      margin-inline: 24px;
    }

    @include on-desktop {
      margin: 0;
      width: 128px;
      height: $header-height-desktop;
    }

    img {
      width: 100%;
      height: 100%;

      @include on-desktop {
        width: 100%;
        height: 100%;
      }
    }
  }
  &--footer-logo {
    img {
      width: 100px;
    }
  }

  &--block {
    border-inline: 1px solid $elements;
    width: $header-height;
    height: $header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      box-shadow: none;
    }

    @include on-desktop {
      width: $header-height-desktop;
      height: $header-height-desktop;
    }

    &-active {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 45px;
        height: 3px;
        width: 100%;
        background-color: $primary;

        @include on-desktop {
          top: 60px;
        }
      }
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  &--menu {
    @include on-tablet {
      display: none;
    }
  }

  &--nav-menu {
    display: flex;
  }

  &--nav-favourites, &--nav-cart {
    width: 50%;
    justify-content: center;
    padding-block: 24px;
    border-top: 1px solid $elements;

    &:hover {
      border-bottom: 1px solid $primary;
    }
  }

  &--nav-favourites {
    border-right: 1px solid $elements;
  }

  &--favourites, &--cart {
    display: none;

    @include on-tablet {
      display: flex;

      &:hover {
        border: 1px solid $primary;
      }
    }
  }

  &--favourites--button {
    width: 40px;
    height: 40px;
    background-color: white;
    border: 1px solid $icons;
    display: flex;
    justify-content: center;
    align-items: center;

    &-active {
      border: 1px solid $primary;
    }
  }
}
