@import '../../../../App.scss';

.drone {
  border: 1px solid $elements;
  border-radius: 20px;
  overflow: hidden;
  width: fit-content;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  position: relative;
  
  &__img {
    overflow: hidden;
    width: 300px !important;
    height: 300px !important;
    object-fit: cover;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__amount {
    @include body-text;
    font-weight: 700;
    color: $primary;
    text-align: center;
    padding: 10px 20px;
    background-color: $elements;
    border: 1px solid $elements;
    border-radius: 0 20px 0 20px;
    z-index: 1;
    position: absolute;
    right: 0;
  }

  &__name, &__price {
    @include body-text;
    font-weight: 700;
    color: $primary;
    text-align: center;
    padding: 10px;
    border-top: 1px solid $elements;
    z-index: 1;
  }
}
