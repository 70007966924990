@import '../../../../App.scss';

.product {
  &__filter {
    position: relative;
    padding-bottom: 32px;
    @include grid;

    @include on-tablet {
      padding-top: 40px;
    }

    &--text {
      @include small-text;
      color: $secondary;
      padding-bottom: 4px;
    }

    &--sort-by {
      position: relative;
      grid-column: 1 / 3;

      @include on-tablet {
        grid-column: 1 / 5;
      }
    }

    &--per-page {
      position: relative;
      grid-column: 3 / -1;

      @include on-tablet {
        grid-column: 5 / 8;
      }
    }
  }

  &__dropdown-button {
    width: 100%;
    background-color: $white;
    border: 1px solid $icons;
    padding: 10px 12px 9px 12px;
    @include buttons;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    &:hover {
      border: 1px solid $primary;
    }

    &:focus {
      border: 1px solid $primary;
    }
  }

  &__dropdown-menu {
    position: absolute;
    top: 68px;
    background-color: $white;
    border: 1px solid $icons;
    width: 100%;
    z-index: 3;
  }

  &__dropdown-content {
    display: flex;
    flex-direction: column;
    padding-block: 8px;
  }

  &__dropdown-item {
    @include body-text;
    color: $secondary;
    padding: 6px 12px;
    cursor: pointer;
    width: 100%;

    &:hover {
      color: $primary;
    }
  }

  &__all {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
    column-gap: 16px;
    row-gap: 40px;

    @include on-tablet {
      grid-template-columns: repeat(auto-fill, minmax(229px, 1fr));
    }

    @include on-desktop {
      grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
    }

    .phone-card {
      display: inline-block;
    }
  }
}
