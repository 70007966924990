@mixin on-tablet {
  @media (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin on-desktop {
  @media (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin content-padding-inline() {
  padding-inline: 16px;
  margin: 0 auto;

  @include on-tablet {
    padding-inline: 24px;
  }

  @include on-desktop {
    max-width: 1200px;
  }
}

@mixin hover($_property, $_toValue) {
  transition: #{$_property} 0.3s;
  &:hover {
    #{$_property}: $_toValue;
  }
}

@mixin grid {
  display: grid;
  column-gap: 16px;

  --columns: 4;

  grid-template-columns: repeat(var(--columns), 1fr);

  @include on-tablet {
    --columns: 12;
  }

  @include on-desktop {
    --columns: 24;
  }
}

@mixin h1 {
  font-family: "Mont", sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  letter-spacing: -0.01em;

  @include on-tablet {
    font-size: 48px;
    line-height: 56px;
  }
}

@mixin h2 {
  font-family: "Mont", sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  letter-spacing: 0em;

  @include on-tablet {
    font-size: 32px;
    line-height: 41px;
    letter-spacing: -0.01em;
  }
}

@mixin h3 {
  font-family: "Mont", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0em;

  @include on-tablet {
    font-size: 22px;
    line-height: 31px;
  }
}

@mixin h4 {
  font-family: "Mont", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;

  @include on-tablet {
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin uppercase {
  font-family: "Mont", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

@mixin buttons {
  font-family: "Mont", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
}

@mixin body-text {
  font-family: "Mont", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
}

@mixin small-text {
  font-family: "Mont", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
}
