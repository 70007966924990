$tablet-min-width: 639px;
$desktop-min-width: 1199px;

$header-height: 48px;
$header-height-desktop: 64px;

$primary: #313237;
$secondary: #89939A;
$icons: #B4BDC3;
$elements: #E2E6E9;
$hover_bg: #FAFBFC;
$white: #FFFFFF;
$green: #27AE60;
$red: #EB5757;
