@import '../../../../App.scss';

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @include on-tablet {
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    height: fit-content;
    position: relative;

    @include on-tablet {
      max-width: 800px;
    }

    @include on-desktop {
      max-width: 1100px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include on-tablet {
      flex-direction: column;
      gap: 20px;
    }

    &--network-error {
      background: $primary;
      color: white;
      padding: 10px 20px;
      @include buttons;
    }

    &--text {
      @include h3;
      color: $primary;
    }

    &--box {
      display: flex;
      gap: 16px;
    }

    &--buttons {
      display: flex;
      gap: 16px;

      &-button {
        width: 40px;
        height: 40px;
        background: $primary;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: white;
          @include buttons;
        }
      }

      &-number {
        @include h4;
        color: $primary;
        padding-top: 10px;
      }
    }

    &--buy {
      &-button {
        @include on-tablet {
          width: 150px;
          height: 40px;
          background: $primary;
          color: white;
          @include buttons;
        }
  
        @include on-desktop {
          width: 300px;
        }
      }

      &-success {
        animation: opacity .3s linear;
        @include on-tablet {
          width: 150px;
          height: 40px;
          background: $green;
          color: white;
          @include buttons;
        }
  
        @include on-desktop {
          width: 300px;
        }
      }
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
      &-failed {
        animation: opacity .3s linear;
        @include on-tablet {
          width: 150px;
          height: 40px;
          background: $red;
          color: white;
          @include buttons;
        }
  
        @include on-desktop {
          width: 300px;
        }
      }
    }

    &--input {
      width: 40px;
      height: 40px;
      @include h4;
      color: $primary;
    }
  }

  &__close {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &--buttons {
      display: flex;
      gap: 20px;
    }
  }

  &__web {
    background-color: $primary;
    height: 40px;
    width: 300px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include buttons;
    gap: 15px;

    &--connected {
      background-color: $white;
      border: 1px solid $green;
      color: $green;
    }
  }
  
  &__text {
    @include body-text;
    color: $secondary;
  }
  
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
