@import '../../App.scss';

.contacts {
  &__networks {
    h2 {
      @include h2;
      color: $primary;
    }

    span {
      @include body-text;
      color: $secondary;
    }
  }
  
  &__social {
    margin-block: 16px;
    display: flex;
    align-items: center;
    gap: 16px;

    a {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  
  &__data {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include on-tablet {
      flex-direction: row;
      gap: 16px;
    }
  }
}

iframe {
  width: 100%;
  height: 300px;

  @include on-tablet {
    width: 600px;
    height: 400px;
  }
}
